import React from 'react';
import isEmpty from '../../../Utilities/is_empty';
import FoodItemSlide from '../Common/FoodItemSlide';
import { Slide } from 'pure-react-carousel';
import MenuDetailPageCarousel from './MenuDetailPageCarousel';
import FootItemCarouselNextButton from '../SVGs/FootItemCarouselNextButton.svg';
import FootItemCarouselBackButton from '../SVGs/FootItemCarouselBackButton.svg';
import { MenuDetailPageParentRef, MenuSections } from './MenuArea';
import { Delivery } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import { FoodItem, RecentOrder } from '../../../Interfaces/PayloadsAndResponses/Menu';

export type GenericOrderAndFoodItem = { fooditem: FoodItem, order: RecentOrder | null, paired?: boolean }

type MenuSectionFoodItemsProps = {
  foodItems: FoodItem[],
  cutOffPassed: boolean;
  currentMeeting?: Delivery;
  showCarouselButtons: boolean;
  parentRef: MenuDetailPageParentRef;
  fromMarketplace?: boolean;
  fromDashboard?: boolean;
  fromInvitedMeeting?: boolean;
  noOfSlidesForFoodItems: number;
  sectionType: MenuSections;
  sortedByState?: string;
  recentOrdersData?: RecentOrder[];
}

const MenuSectionFoodItems = ({
  cutOffPassed,
  currentMeeting,
  fromDashboard = false,
  fromInvitedMeeting = false,
  fromMarketplace = false,
  parentRef,
  sectionType,
  foodItems,
  noOfSlidesForFoodItems,
  showCarouselButtons,
  sortedByState,
  recentOrdersData = [],
}: MenuSectionFoodItemsProps) => {


  const getFoodItemsBasedOnImages = (foodItemsList: FoodItem[] = []): { foodItemsWithImages: GenericOrderAndFoodItem[], foodItemsWithoutImages: GenericOrderAndFoodItem[] } => {
    let foodItemsWithImages: GenericOrderAndFoodItem[] = [], foodItemsWithoutImages: GenericOrderAndFoodItem[] = [];
    foodItemsList?.forEach((fooditem, index) => {
      if (fooditem?.image?.medium?.url) {
        foodItemsWithImages.push({ fooditem, order: recentOrdersData[index] ?? null });
      } else {
        foodItemsWithoutImages.push({ fooditem, order: recentOrdersData[index] ?? null });
      }
    });
    if (isEmpty(foodItemsWithImages)) {
      foodItemsWithImages = foodItemsWithoutImages;
      foodItemsWithoutImages = [];
    }
    return { foodItemsWithImages, foodItemsWithoutImages }
  }

  const { foodItemsWithImages, foodItemsWithoutImages } = getFoodItemsBasedOnImages(isEmpty(foodItems) ? [] : foodItems);

  const sortByRating = (foodItemsList: FoodItem[] = []) => {
    let foodItems: GenericOrderAndFoodItem[] = []
    let sortedFoodItemsArray: FoodItem[] = []
    if(sortedByState === 'rating'){
      // const sortedList = foodItemsList.sort((a, b) => (b.average_rating || 0) - (a.average_rating || 0));
      const sortedList = foodItemsList.sort((a, b) => {
        const diff = (b.average_rating || 0) - (a.average_rating || 0);
        if (diff) return diff;
    
        const aHasImage = a.image?.medium?.url ? 1 : 0;
        const bHasImage = b.image?.medium?.url ? 1 : 0;
    
        return bHasImage - aHasImage; // Prefer item with image
      });
      sortedFoodItemsArray = sortedList

    }else if (sortedByState === 'price'){
      // const sortedList = foodItemsList.sort((a, b) => (b.gross_price || b.price) - (a.gross_price || a.price));
      const sortedList = foodItemsList.sort((a, b) => {

        if ((b.gross_price || b.price) !== (a.gross_price || a.price)) {
          return (b.gross_price || b.price) - (a.gross_price || a.price);
        }
      
        const aHasImage = a.image?.medium?.url ? 1 : 0;
        const bHasImage = b.image?.medium?.url ? 1 : 0;
      
        return bHasImage - aHasImage; // item with image comes first
      });
      
      sortedFoodItemsArray = sortedList
    }
    sortedFoodItemsArray?.forEach((fooditem, index) => {
      foodItems.push({ fooditem, order: recentOrdersData[index] ?? null });
    });
    return foodItems;
  };

  // First, sort all items (both with and without images) by rating
  const sortedFoodItems = sortByRating(foodItems);

  let totalSlides = 0;
  for (let i = 0; i < sortedFoodItems.length; i++) {
    const item = sortedFoodItems[i];
    const isImageItem = !isEmpty(item?.fooditem.image?.medium?.url);

    if (isImageItem) {
      totalSlides++;
    } else {
      // Non-image items need to be paired
      if (i + 1 < sortedFoodItems.length && !isEmpty(sortedFoodItems[i + 1]?.fooditem.image?.medium?.url)) {
        totalSlides++;
        i++; // Skip the next item since it's already paired with this one
      } else {
        totalSlides++; // If it's the last non-image item, it occupies one full slide (half empty)
      }
    }
  }
  return <>{(sortedByState === 'rating' || sortedByState === 'price') ? <>  <MenuDetailPageCarousel
    totalSlides={Math.ceil(totalSlides)}
    visibleSlides={noOfSlidesForFoodItems}
    showCarouselButtons={showCarouselButtons}
    NextButtonContent={<img alt='Next' src={FootItemCarouselNextButton} />}
    BackButtonContent={<img alt='Back' src={FootItemCarouselBackButton} />}
    NextButtonStyles={{ right: '10px', height: '50px', width: '50px' }}
    BackButtonStyles={{ left: '15px', height: '50px', width: '50px' }}
  >
    {sortedFoodItems.map((item, sectionIndex) => {
      const secondItem = sortedFoodItems[sectionIndex + 1];
      if (item.paired) return null;

      const isImageItem = !isEmpty(item?.fooditem.image?.medium?.url);
      const isSecondItemImage = secondItem && !isEmpty(secondItem?.fooditem.image?.medium?.url);
      // If both the current and next item are non-image items, we create a pair
      const pairExists = !isImageItem && secondItem && !isSecondItemImage;

      // If a pair exists, skip the second item in the next iteration
      if (pairExists) {
        secondItem.paired = true;
      }
      return (
        <Slide className={isImageItem ? 'new-section py-4' : 'py-4'} index={sectionIndex} key={item?.fooditem?.id + '-' + sectionIndex}>
          <div className="d-flex flex-column justify-content-between h-100" style={{ gap: 10 }}>
            {/* First item - Always have a full slide if it has an image */}
            <FoodItemSlide
              fromDashboard={fromDashboard}
              fromMarketplace={fromMarketplace}
              sectionType={sectionType}
              fromInvitedMeeting={fromInvitedMeeting}
              parentRef={parentRef}
              fooditem={item.fooditem}
              deliveryDetails={currentMeeting}
              className={isImageItem ? 'h-100' : ''}
              foodItemSlideStyles={isImageItem ? { flexGrow: 1 } : { flexGrow: 1, maxHeight: `calc(50% - 10px)` }}
              cutOffPassed={cutOffPassed}
              recentOrder={item.order}
            />

            {/* Pair with second item if non-image and exists */}
            {!isImageItem && secondItem && !isSecondItemImage && (
              <FoodItemSlide
                fromDashboard={fromDashboard}
                fromMarketplace={fromMarketplace}
                sectionType={sectionType}
                fromInvitedMeeting={fromInvitedMeeting}
                parentRef={parentRef}
                fooditem={secondItem.fooditem}
                deliveryDetails={currentMeeting}
                foodItemSlideStyles={{
                  flexGrow: 1,
                  maxHeight: !isSecondItemImage ? '100%' : `calc(50% - 10px)`, // Non-image with image: keep it half
                }}
                cutOffPassed={cutOffPassed}
                recentOrder={secondItem.order}
              />
            )}
          </div>
        </Slide>
      );
    })}
  </MenuDetailPageCarousel> </>
    :
 <MenuDetailPageCarousel
    totalSlides={foodItemsWithImages?.length + Math.ceil(foodItemsWithoutImages?.length / 2)}
    visibleSlides={noOfSlidesForFoodItems}
    showCarouselButtons={showCarouselButtons}
    NextButtonContent={<img alt='Next' src={FootItemCarouselNextButton} />}
    BackButtonContent={<img alt='Back' src={FootItemCarouselBackButton} />}
    NextButtonStyles={{ right: '10px', height: '50px', width: '50px' }}
    BackButtonStyles={{ left: '15px', height: '50px', width: '50px' }}
  >
    {foodItemsWithImages?.map((item, sectionIndex) => (
      <Slide className='new-section py-4' key={item.fooditem?.id + '-' + sectionIndex} index={sectionIndex}>
        <FoodItemSlide
          fromDashboard={fromDashboard}
          fromMarketplace={fromMarketplace}
          fromInvitedMeeting={fromInvitedMeeting}
          parentRef={parentRef}
          fooditem={item.fooditem}
          sectionType={sectionType}
          cutOffPassed={cutOffPassed}
          className='h-100'
          foodItemSlideStyles={{ flexGrow: 1 }}
          deliveryDetails={currentMeeting}
          recentOrder={item.order}
        />
      </Slide>
    ))}
    {foodItemsWithoutImages?.map((item, sectionIndex) => {
      const secondItem = foodItemsWithoutImages[sectionIndex + 1];
      const lastItem = sectionIndex === foodItemsWithoutImages?.length - 1;
      return sectionIndex % 2 === 0 ?
        <Slide className='py-4' index={sectionIndex} key={item.fooditem?.id + '-' + sectionIndex}>
          <div className={`d-flex flex-column justify-content-between h-100`} style={{ gap: 10 }}>
            <FoodItemSlide
              fromDashboard={fromDashboard}
              fromMarketplace={fromMarketplace}
              sectionType={sectionType}
              fromInvitedMeeting={fromInvitedMeeting}
              parentRef={parentRef}
              fooditem={item.fooditem}
              deliveryDetails={currentMeeting}
              foodItemSlideStyles={lastItem ? {} : { flexGrow: 1, maxHeight: `calc(50% - 10px)` }}
              cutOffPassed={cutOffPassed}
              recentOrder={item.order}
            />
            {secondItem &&
              <FoodItemSlide
                fromDashboard={fromDashboard}
                fromMarketplace={fromMarketplace}
                sectionType={sectionType}
                fromInvitedMeeting={fromInvitedMeeting}
                parentRef={parentRef}
                fooditem={secondItem.fooditem}
                deliveryDetails={currentMeeting}
                foodItemSlideStyles={{ flexGrow: 1, maxHeight: `calc(50% - 10px)` }}
                cutOffPassed={cutOffPassed}
                recentOrder={item.order}
              />
            }
          </div>
        </Slide> : ''
    })}
  </MenuDetailPageCarousel>
}
</>
}

export default MenuSectionFoodItems;